import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import {site} from "../../theme"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const BlogAvatar = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "blogavatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(height: 38, width: 38, layout: FIXED)
    }
  }
}
`}
    render={(data) => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        className="blog-avatar"
        alt={site.author} 
        style={{display:`inline-block`}}
        />
    )}
  />
)
export default BlogAvatar
