import React from "react"
import {
  Card,
  CardTitle,
  CardText,
  CardBody,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

import {site, sidebar, colors} from "../../theme"

const Image = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 200, quality: 100, layout: CONSTRAINED)
    }
  }
}
`}
    render={(data) => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt={site.title} />
    )}
  />
)

const Sidebar = () => (
  <aside>
    <section>
      <Widget>
        <CardBody>
          <BioAvatar>
            <Image />
          </BioAvatar>
          <CardTitle className="text-center text-white mb-0" dangerouslySetInnerHTML={{ __html: sidebar.avatartitle }} />
          <BioText dangerouslySetInnerHTML={{ __html: sidebar.avatarsubtitle }} />
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle className="text-center text-white mb-3" dangerouslySetInnerHTML={{ __html: sidebar.optin }} />
          <Form
            className="text-center"
            action={"https://www.getdrip.com/forms/" + sidebar.drip + "/submissions"}
            method="post"
            data-drip-embedded-form={sidebar.drip}
          >
            <FormGroup>
              <Input
                type="email"
                name="fields[email]"
                id="drip-email"
                aria-label="Email"
                placeholder="Your email address..."
              />
            </FormGroup>
            <button
              data-drip-attribute="sign-up-button"
              className="btn text-white text-uppercase joinsubmit"
            >
              {sidebar.optinbutton}
            </button>
          </Form>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: colors.highlight }}>Resources</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href="/resources/">
              {sidebar.resourcepage}
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: colors.highlight }}>Popular Articles</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem
              bold
              tag="a"
              href={sidebar.postone.url}
            >
              {sidebar.postone.name}
            </MenuItem>
            <MenuItem bold tag="a" href={sidebar.posttwo.url}>
            {sidebar.posttwo.name}
            </MenuItem>
            <MenuItem bold tag="a" href={sidebar.postthree.url}>
            {sidebar.postthree.name}
            </MenuItem>
            <MenuItem bold tag="a" href={sidebar.postfour.url}>
            {sidebar.postfour.name}
            </MenuItem>
            <MenuItem
              bold
              tag="a"
              href={sidebar.postfive.url}
            >
              {sidebar.postfive.name}
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
      <Widget>
        <CardBody>
          <CardTitle>
            <h5 style={{ color: colors.highlight }}>Categories</h5>
          </CardTitle>
          <ListGroup>
            <MenuItem tag="a" href={sidebar.catone.url}>
              {sidebar.catone.name}
            </MenuItem>

            <MenuItem tag="a" href={sidebar.cattwo.url}>
            {sidebar.cattwo.name}
            </MenuItem>

            <MenuItem tag="a" href={sidebar.catthree.url}>
            {sidebar.catthree.name}
            </MenuItem>
          </ListGroup>
        </CardBody>
      </Widget>
    </section>
    <section />
  </aside>
)

export default Sidebar

const Widget = styled(Card)`
  background-color: ${colors.darkbg} !important;
  border-radius: 2px;
  border: none !important;
  margin-bottom: 0.5rem;
  //min-height: 200px;
  text-align: center;
`
const BioAvatar = styled.div`
  margin: auto;
  margin-bottom: 15px;
  max-width: 200px;
  align-self: center;
  img {
    background-color: ${colors.highlight};
    border-radius: 50%;
    border: 8px solid ${colors.highlight};
  }
`
const BioText = styled(CardText)`
  color: #fff;
  line-height: 1.2;
`
const SidebarLink = styled(Link)`
  color: #fff !important;
  &:hover {
    color: ${colors.highlight};
  }
`
const MenuItem = styled(ListGroupItem)`
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  &:hover {
    background: ${colors.highlight} !important;
  }
`
