import React from "react"
import styled from "styled-components"

import {colors} from "../../../theme"

export function TOC(props) {
  return <Styling>{props.children}</Styling>
}

const Styling = styled.div`
  background-color: ${colors.darkbg};
  //border: 1px dashed #000;
  border-radius: 6px;
  color: #fff;
  padding: 20px;
  margin-bottom: 20px;

  p {
    font-family: "Jost" !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
  }
  & a:hover {
    //color: #fff !important;
  }
  & ul {
    padding: 0;
  }
  & ul li {
    font-size: 1.2rem !important;
    list-style: none;
    line-height: 1.6;
    margin: 0;
  }
  & ol {
    padding: 0;
  }
  & ol li {
    font-size: 1.2rem !important;
    list-style: none;
    line-height: 1.6;
    margin: 0;
    a {
      color: ${colors.lightbg} !important;
      border-bottom: 0 !important;
      &:hover {
        //color: var(--tochover) !important;
        background: transparent !important;
        text-decoration: underline !important;
      }
    }
  }
  & ol li,
  ul li {
    & ol,
    ul {
      margin-left: 30px;
    }
  }
`
