import React from "react"
import { Container, Row, Col, Media } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"

import {site, bio, colors} from "../../theme"

const Image = () => (
  <StaticQuery
    query={graphql`{
  placeholderImage: file(relativePath: {eq: "avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(height: 100, quality: 100, layout: FIXED)
    }
  }
}
`}
    render={(data) => (
      <GatsbyImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        alt={site.title} />
    )}
  />
)

const Bio = () => (
  <Container fluid={true} className="jointheform py-3">
    <Row>
      <Col sm="12" className="muted">
        <section>
          <Media
            className="shadow rounded p-2"
            style={{ background: colors.lightbg, maxWidth: 500, margin: `auto` }}
          >
            <BlogAvatar>
              <Image />
            </BlogAvatar>
            <Media body>
              <h5 className="mt-0 mb-0" style={{ fontFamily: `Jost` }}>
                <a href="/">
                  <b>{site.title}</b>
                </a>
              </h5>
              <small style={{ color: `#fff` }}>
                <i>
                  {bio.text}
                </i>
                <br /> <b>Join our community by signing up.</b>
              </small>
            </Media>
          </Media>
        </section>
      </Col>
    </Row>
  </Container>
)

export default Bio

const BlogAvatar = styled.div`
  margin-right: 1rem;
  align-self: center;
  img {
    border-radius: 50%;
    border: 5px solid ${colors.highlight};
  }
`
